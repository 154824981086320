<template>
    <div>
        <form-field caption="Propriétaire">
            <b-form-input type="text" disabled :value="COMPETITION.OWNER.name" ></b-form-input>
            <b-button v-if="isCurrentUserOwnerOfTheCompetition" @click="changeOwnerDialogVisible = true">Nommer un remplaçant</b-button>
            <template #info-helper-placeholder>
                <info-helper>
                    Le "propriétaire" de la compétition est, par défaut, l'utilisateur qui a créé la compétition dans le système.<br/>
                    Il est donc l'utilisateur possédant le plus de droits sur la configuration et la gestion de la compétition et ne peut être supprimé (ou remplacé) par aucun 
                    autre utilisateur, à moins que celui-ci soit nommé directement par l'actuel propriétaire (lui-même) pour le remplacer.
                </info-helper>
            </template>
        </form-field>
        <form-field caption="Administrateurs">
            <b-container fluid style="padding-left:-20px;">
                <competition-admin v-for="admin in ADMINS"
                    :key="'adm_' + admin.name"
                    :value="admin.name" 
                    :canDelete = "canDeleteAdmin(admin)"
                    @delete="delete_admin(admin)"
                ></competition-admin>
                <competition-admin v-if="isCurrentUserOwnerOfTheCompetition"
                    canAdd
                    @add="addAdminDialogVisible = true"
                ></competition-admin>
            </b-container>
        </form-field>

        <find-user-dialog :selectedUser="COMPETITION.OWNER" title="Choix du propriétaire remplaçant" :visible="changeOwnerDialogVisible" @ok="owner_change" @hide="changeOwnerVisible=false"></find-user-dialog>
        <find-user-dialog title="Choix du nouvel administrateur" :visible="addAdminDialogVisible" @ok="add_admin" @hide="addAdminDialogVisible=false"></find-user-dialog>
    </div>
</template>

<script>
import { RST_COMPETITION, /*RST_COMPETITION_ADMIN*/ } from "@/LocalDB";
import FormField from '../../../components/Panels/FormField.vue';
import InfoHelper from '../../../components/InfoHelper.vue';
import CompetitionAdmin from './CompetitionAdmin.vue';
import FindUserDialog from "../../../components/Dialogs/FindUserDialog.vue";
import CompetitionsConfigurationHelper from "../../../services/helpers/competitionsConfigurationHelper";

export default
{
  components: { FormField, InfoHelper, CompetitionAdmin, FindUserDialog },
    props:{
        competition_id: { type:String, required:true }
    },
    data(){
        return {
            changeOwnerDialogVisible: false,
            addAdminDialogVisible: false,
        };
    },
    computed:{
        /**
         * returns {RST_COMPETITION}
         */
        COMPETITION(){
            return RST_COMPETITION.find(this.competition_id);
        },
        ADMINS(){
            //console.log(this.COMPETITION.ADMINS)
            return this.COMPETITION.ADMINS;
        },
        isCurrentUserOwnerOfTheCompetition(){
            return this.COMPETITION.OWNER.visa == this.currentUser.visa;
        },
        isCurrentUserOwnerOrAdminOfTheCompetition(){
            if(this.isCurrentUserOwnerOfTheCompetition)
                return true;
                console.log(this.ADMINS, this.currentUser.visa)
            return (this.ADMINS.findIndex(adm => adm.visa == this.currentUser.visa) >= 0);
        },
    },
    methods:{
        canDeleteAdmin(admin){
            if(!this.isCurrentUserOwnerOrAdminOfTheCompetition)
                return false;
            return admin.visa != this.currentUser.visa;
        },
        async owner_change(newOwner){
            var confirmation = await this.$bvModal.msgBoxConfirm("Etes-vous sûr de vouloir remplacer l'actuel propriétaire de la compétition par " + newOwner.name + " ?")
            if(confirmation){
                var ret = CompetitionsConfigurationHelper.changeOwnerAsync(this.COMPETITION.id, newOwner.username);
                this.toastAsyncSaved(ret, "Propriété transférée");
                /*if(ret)
                    this.$router.push({name: 'home'});*/
            }
        },
        async delete_admin(admin){
            var confirmation = await this.$bvModal.msgBoxConfirm('Etes-vous sûr de vouloir retirer ' + admin.name + ' de la liste des administrateurs de cette compétition ?');
            if(confirmation)
            {
                var ret = CompetitionsConfigurationHelper.removeAdminAsync(this.COMPETITION.id, admin.visa);
                this.toastAsyncSaved(ret, "Administrateur supprimé");
            }
        },
        async add_admin(newAdmin){
            var confirmation = await this.$bvModal.msgBoxConfirm("Etes-vous sûr de vouloir ajouter " + newAdmin.name + " à la liste des administrateur de cette compétition ?");
            if(confirmation)
            {
                var ret = CompetitionsConfigurationHelper.addAdminAsync(this.COMPETITION.id, newAdmin.username);
                this.toastAsyncSaved(ret, "Administrateur ajouté");
            }
        }
    }

}
</script>