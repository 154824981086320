<template>
        <b-row>
            <b-col v-if="canAdd" sm="1" class="no-padding-left no-padding-right">
                <slot name="add-button-placehoder">
                    <b-button @click="$emit('add')" variant="primary" v-show="canAdd==true" ><add-icon /></b-button>
                </slot>
            </b-col>
            <b-col v-if="canDelete" sm="1" class="no-padding-left no-padding-right">
                <slot name="delete-button-placeholder">
                    <b-button v-show="canDelete==true" @click="$emit('delete')" variant="danger"><delete-icon/></b-button>
                </slot>
            </b-col>
            <b-col class="no-padding-left">
                <b-form-input type="text" disabled :value="d_value"></b-form-input>
            </b-col>
        </b-row>
</template>

<script>
import AddIcon from '../../../components/Icons/AddIcon.vue';
import DeleteIcon from '../../../components/Icons/DeleteIcon.vue';

export default {
  components: { AddIcon, DeleteIcon },
    props: {
        value: {type: String, default: ''},
        canAdd: {type: Boolean, default: false },
        canDelete: { type:Boolean, default: false},
    },
    data(){
        return {
            d_value : this.value,
        }
    },
    computed:{
        _canAdd(){
            return this.canAdd && (!this.value || this.value == null || this.value.trim() == '');
        },
        _canEdit(){
            return this.canEdit && this.value && this.value != null && this.value.trim() != '';
        },
        _canDelete(){
            return this.canDelete && this.value && this.value != null && this.value.trim() != '';
        }
    },
    watch:{
        value: function(newValue){
            this.d_value = newValue;
        }
    }
}
</script>

<style>
    .no-padding-left{ padding-left: 0px; }
    .no-padding-right{ padding-right: 0px; }
</style>