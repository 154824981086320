<template>
    <b-modal
      :title="title"
      centered
      :static="true"
      :lazy="true"
      size="lg"
      v-if="d_visible"
      v-model="d_visible"
      @hide="onHide"
      @ok="onOk"
      button-size="lg"
    >
      <form-field caption="Recherche d'utilisateur">
      <vue-bootstrap-typeahead
          v-model="selectedUsername"
          :data="options"
          :minMatchingChars="1"
          :serializer="s => s.text"
          placeholder="recherche par nom, prénom"
          @hit="search_hit"
          style="width:100%"
      >
      </vue-bootstrap-typeahead>
      </form-field>
    </b-modal>
</template>

<script>
import { PEOPLE } from "@/LocalDB";
//import SecuredArea from '@/components/Security/SecuredArea.vue';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import FormField from '../Panels/FormField.vue';

export default{
  components: { VueBootstrapTypeahead, FormField},
  model:{
    prop: 'visible',
    event: 'change'
  },
  props: { visible: { type:Boolean, default: false}, title:{ type: String, default: "Recherche d'utilisateur"}, selectedUser: { type: Object, default: null} },
  data(){
    return {
      bufSelectedUsername: null,
      bufSelecteddName:null,
      bufSelectedPeopleId: null,

      d_visible: this.visible,
    }
  },
  computed: {
    selectedUsername:{
      get(){
        if(this.bufSelectedUsername == null)
          return (this.selectedUser != null) ? this.selectedUser.name : '';
        return this.bufSelectedUsername;
      },
      set(value){
        this.bufSelectedUsername = value;
      }
    },
    selectedName: {
      get(){
        if(this.bufSelectedName == null)
          return (this.selectedUser != null) ? this.selectedUser.firstname + ' ' + this.selectedUser.lastname : '';
        return this.bufSelecteddName;
      },
      set(value){
        this.bufSelectedName = value;
      }
    },
    selectedPeopleId: {
      get(){
        if(this.bufSelectedPeopleId == null)
          return (this.selectedUser != null) ? this.selectedUser.id : '';
        return this.bufSelectedPeopleId;
      },
      set(value){
        this.bufSelectedPeopleId = value;
      }
    },
    options(){
      var users = PEOPLE.query().where(p => { return p.User_Id != null && p.VISA != null && p.VISA.visa != null && p.VISA.visa != ''}).orderBy('name').get();
      return users.map(p => {
        return { value: p.VISA.visa, text: p.name, id: p.id};
      });
    },
  },
  methods: {
    onOk(){
      if(this.bufSelectedUsername && this.bufSelectedUsername != '' && this.bufSelectedPeopleId != '')
      {
        this.$emit("ok", { username: this.bufSelectedUsername, name: this.bufSelectedName, people_id: this.bufSelectedPeopleId} );
      }
      this.reset();
    },
    search_hit(selected){
      this.selectedUsername = selected.value;
      this.selectedName = selected.text;
      this.selectedPeopleId = selected.id;
    },
    onHide(){
      this.$emit('change', false);
      this.$emit('hide');
    },
    reset(){
      this.bufSelectedUsername = null;
      this.bufSelectedName = null;
      this.bufSelectedPeopleId = null;
    }
  },
  mounted(){
    this.reset();
  },
  watch:{
    visible : function(newValue){
      this.d_visible = newValue;
    }
  }

}
</script>
